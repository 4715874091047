import React from "react";

class FormationDescription extends React.Component {
    render() {
        return (
            <div className="body">
                <div>Cette page contiendra le contenu de la Formation</div>
            </div>
        );
    }
}

export default FormationDescription;
